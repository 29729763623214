import { CompanyDefaultInvoiceGroupGuard } from 'src/app/shared/route-guards/company-default-invoiceGroup-guard';
import { AddContactToNotificationWhitelistComponent } from './modals/add-contact-to-notification-whitelist/add-contact-to-notification-whitelist.component';
import { MergeBusinessEntityGridHeaderComponent } from './modals/merge-business-entity-grid-header/merge-business-entity-grid-header.component';
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {
	PerfectScrollbarConfigInterface,
	PerfectScrollbarModule,
	PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { NoSanitizePipe } from 'src/app/shared/pipes/no-sanitize.pipe';
import { CurrentContractPageComponent } from '../modules/companies/current-contract-page/current-contract-page.component';
import { ProfileTemplateItemComponent } from '../modules/settings/profile-templates/profile-template-item/profile-template-item.component';
import { ProfileTemplatesComponent } from '../modules/settings/profile-templates/profile-templates.component';
import { ScriptsListComponent } from '../modules/settings/scripts-list/scripts-list.component';
import { AppStoreModule } from '../store/store.module';
import { AccordionGroupComponent } from './components/accordion-group/accordion-group.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccountingRenewalTableComponent } from './components/accounting-renewal-table/accounting-renewal-table.component';
import { AddAdditionalFeeComponent } from './components/add-additional-fee/add-additional-fee.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { ApplicationPageHeaderComponent } from './components/application-page-header/application-page-header.component';
import { BusinessEntitySearchComponent } from './components/business-entity-search/business-entity-search.component';
import { CommunicationChatComponent } from './components/communication-chat/communication-chat.component';
import { CompanyActivateFormComponent } from './components/company-activate-form/company-activate-form.component';
import { CompanyAppFormComponent } from './components/company-app-form/company-app-form.component';
import { CompanyContractAppFormComponent } from './components/company-contract-app-form/company-contract-app-form.component';
import { CompanyInactivateFormComponent } from './components/company-inactivate-form/company-inactivate-form.component';
import { CompanyInfoFormComponent } from './components/company-info-form/company-info-form.component';
import { ContactInfoFormComponent } from './components/contact-info-form/contact-info-form.component';
import { ContactsAppFormComponent } from './components/contacts-app-form/contacts-app-form.component';
import { ContractFormComponent } from './components/contract-form/contract-form.component';
import { DocumentTemplateComponent } from './components/document-template/document-template.component';
import { DynamicMenuComponent } from './components/dynamic-menu/dynamic-menu.component';
import { EntityFormLineItemComponent } from './components/entity-form/entity-form-line-item/entity-form-line-item.component';
import { EntityFormComponent } from './components/entity-form/entity-form.component';
import { EntityGuidelinesComponent } from './components/entity-guidelines/entity-guidelines.component';
import { EntityProfileAsweredQuestionsListComponent } from './components/entity-profile-aswered-questions-list/entity-profile-aswered-questions-list.component';
import { FacilitiesAppFormComponent } from './components/facilities-app-form/facilities-app-form.component';
import { FacilityActivateFormComponent } from './components/facility-activate-form/facility-activate-form.component';
import { FacilityContractSummaryComponent } from './components/facility-contract-summary/facility-contract-summary.component';
import { FacilityContractDetailsComponent } from './components/facility-contract/facility-contract-details/facility-contract-details.component';
import { FacilityContractTermsComponent } from './components/facility-contract/facility-contract-fees-and-expenses/facility-contract-fees-and-expenses.component';
import { TermExpenseRowComponent } from './components/facility-contract/facility-contract-fees-and-expenses/contract-item-row/contract-item-row.component';
import { FacilityContractVisitFeesComponent } from './components/facility-contract/facility-contract-visit-fees/facility-contract-visit-fees.component';
import { VisitFeesRowComponent } from './components/facility-contract/facility-contract-visit-fees/visit-fees-row/visit-fees-row.component';
import { FacilityContractComponent } from './components/facility-contract/facility-contract.component';
import { FacilityFormComponent } from './components/facility-form/facility-form.component';
import { FacilityInactivateFormComponent } from './components/facility-inactivate-form/facility-inactivate-form.component';
import { FacilityInfoFormComponent } from './components/facility-info-form/facility-info-form.component';
import { FeesAndExpensesAddFormComponent } from './components/fees-and-expenses-table/fees-and-expenses-add-form/fees-and-expenses-add-form.component';
import { FeesAndExpensesInputFormComponent } from './components/fees-and-expenses-table/fees-and-expenses-input-form/fees-and-expenses-input-form.component';
import { FeesAndExpensesTableComponent } from './components/fees-and-expenses-table/fees-and-expenses-table.component';
import { GenerateContractComponent } from './components/generate-contract/generate-contract.component';
import { HeaderComponent } from './components/header/header.component';
import { HistoryDisplayItemComponent } from './components/history-display-item/history-display-item.component';
import { IngredientFacilitiesComponent } from './components/ingredient-facilities/ingredient-facilities.component';
import { CustomerIngredientGeneralInfoComponent } from './components/ingredients/customer-ingredient-general-info/customer-ingredient-general-info.component';
import { CustomerIngredientsMasterIngredientComponent } from './components/ingredients/customer-ingredients-master-ingredient/customer-ingredients-master-ingredient.component';
import { IngredientCertificateAttributeComponent } from './components/ingredients/ingredient-certificate-attribute/ingredient-certificate-attribute.component';
import { MasterIngredientCurrentCertificateComponent } from './components/ingredients/master-ingredient-current-certificate/master-ingredient-current-certificate.component';
import { MasterIngredientGeneralInfoComponent } from './components/ingredients/master-ingredient-general-info/master-ingredient-general-info.component';
import { MasterIngredientKosherEvaluationComponent } from './components/ingredients/master-ingredient-kosher-evaluation/master-ingredient-kosher-evaluation.component';
import { MasterIngredientKosherInfoComponent } from './components/ingredients/master-ingredient-kosher-info/master-ingredient-kosher-info.component';
import { PackagingComponent } from './components/ingredients/packaging/packaging.component';
import { InitialVisitAppFormComponent } from './components/initial-visit-app-form/initial-visit-app-form.component';
import { InternalProductsRequiringApprovalComponent } from './components/internal-products-requiring-approval/internal-products-requiring-approval.component';
import { InvoiceSettingsFormComponent } from './components/invoice-settings-form/invoice-settings-form.component';
import { InvoicesTabComponent } from './components/invoices-tab/invoices-tab.component';
import { InvoicingGroupsAppFormComponent } from './components/invoicing-groups-app-form/invoicing-groups-app-form.component';
import { KcGroupComponent } from './components/kc-group/kc-group.component';
import { KosherCertificatesFormComponent } from './components/kosher-certificates-form/kosher-certificates-form.component';
import { MergeIngredientMasterIngredientItemComponent } from './components/merge-ingredient-master-ingredient-item/merge-ingredient-master-ingredient-item.component';
import { MergeIngredientTabComponent } from './components/merge-ingredient-tab/merge-ingredient-tab.component';
import { NoteListComponent } from './components/note-list/note-list.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PermissionsListComponent } from './components/permissions-list/permissions-list.component';
import { PlCurrentContractPageComponent } from './components/pl-current-contract-page/pl-current-contract-page.component';
import { PrivateLabelerAppFormComponent } from './components/private-labeler-app-form/private-labeler-app-form.component';
import { PrivateLabelerContractAppFormComponent } from './components/private-labeler-contract-app-form/private-labeler-contract-app-form.component';
import { PrivateLabelerContractComponent } from './components/private-labeler-contract/private-labeler-contract.component';
import { PrivateLabelerInfoFormComponent } from './components/private-labeler-info-form/private-labeler-info-form.component';
import { PrivateLabelerRelatedFeesComponent } from './components/private-labeler-related-fees/private-labeler-related-fees.component';
import { ProductFormulaReportComponent } from './components/product-formula-report/product-formula-report.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { ReferralFormComponent } from './components/referral-form/referral-form.component';
import { CompanyRenewalHistoryListComponent } from './components/renewal-info/company-renewal-history-list/company-renewal-history-list.component';
import { RenewalInfoComponent } from './components/renewal-info/renewal-info.component';
import { RolesFormComponent } from './components/roles-form/roles-form.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ApplicationSideinfoComponent } from './components/sideinfo-components/application-sideinfo/application-sideinfo.component';
import { CompanyApplicationSideinfoComponent } from './components/sideinfo-components/application-sideinfo/company-application-sideinfo/company-application-sideinfo.component';
import { PrivateLabelerApplicationSideinfoComponent } from './components/sideinfo-components/application-sideinfo/private-labeler-application-sideinfo/private-labeler-application-sideinfo.component';
import { RestaurantApplicationSideinfoComponent } from './components/sideinfo-components/application-sideinfo/restaurant-application-sideinfo/restaurant-application-sideinfo.component';
import { BrandSideinfoComponent } from './components/sideinfo-components/brand-sideinfo/brand-sideinfo.component';
import { CompanySideinfoComponent } from './components/sideinfo-components/company-sideinfo/company-sideinfo.component';
import { CompanySnapshotsSideInfoComponent } from './components/sideinfo-components/company-snapshots-side-info/company-snapshots-side-info.component';
import { ContactSideinfoComponent } from './components/sideinfo-components/contact-sideinfo/contact-sideinfo.component';
import { CustomerIngredientSideinfoComponent } from './components/sideinfo-components/customer-ingredient-sideinfo/customer-ingredient-sideinfo.component';
import { FacilitySideinfoComponent } from './components/sideinfo-components/facility-sideinfo/facility-sideinfo.component';
import { GroupSideinfoComponent } from './components/sideinfo-components/group-sideinfo/group-sideinfo.component';
import { HistorySideinfoComponent } from './components/sideinfo-components/history-sideinfo/history-sideinfo.component';
import { IngredientBatchSideinfoComponent } from './components/sideinfo-components/ingredient-batch-sideinfo/ingredient-batch-sideinfo.component';
import { InvoiceSideinfoComponent } from './components/sideinfo-components/invoice-sideinfo/invoice-sideinfo.component';
import { MagicReviewSideInfoComponent } from './components/sideinfo-components/magic-review-sideinfo/magic-review-side-info.component';
import { MasterIngredientsSideinfoComponent } from './components/sideinfo-components/master-ingredients-sideinfo/master-ingredients-sideinfo.component';
import { NoteSideInfoComponent } from './components/sideinfo-components/note-side-info/note-side-info.component';
import { NotificationSideinfoComponent } from './components/sideinfo-components/notification-sideinfo/notification-sideinfo.component';
import { PrivateLabelerSideinfoComponent } from './components/sideinfo-components/private-labeler-sideinfo/private-labeler-sideinfo.component';
import { ProductReviewQueueSideInfoComponent } from './components/sideinfo-components/product-review-queue-side-info/product-review-queue-side-info.component';
import { ProductSideinfoComponent } from './components/sideinfo-components/product-sideinfo/product-sideinfo.component';
import { RoleSideinfoComponent } from './components/sideinfo-components/role-sideinfo/role-sideinfo.component';
import { TaskSideinfoComponent } from './components/sideinfo-components/task-sideinfo/task-sideinfo.component';
import { TripSideinfoComponent } from './components/sideinfo-components/trip-sideinfo/trip-sideinfo.component';
import { VisitSideinfoComponent } from './components/sideinfo-components/visit-sideinfo/visit-sideinfo.component';
import { SideinfoFacilityComponent } from './components/sideinfo-facility/sideinfo-facility.component';
import { SideinfoComponent } from './components/sideinfo/sideinfo.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { StaticBarComponent } from './components/static-bar/static-bar.component';
import { SummaryFieldListComponent } from './components/summary-field-list/summary-field-list.component';
import { SummaryFieldIconComponent } from './components/summary-field-list/summary-field/summary-field-icon/summary-field-icon.component';
import { SummaryFieldTextComponent } from './components/summary-field-list/summary-field/summary-field-text/summary-field-text.component';
import { SummaryFieldWithLabelComponent } from './components/summary-field-list/summary-field/summary-field.component';
import { TabsMenuComponent } from './components/tabs-menu/tabs-menu.component';
import { TabsModule } from './components/tabs/tabs.module';
import { TaskControlsFormComponent } from './components/task-controls-form/task-controls-form.component';
import { TaskInfoFormComponent } from './components/task-info-form/task-info-form.component';
import { TripInfoFormComponent } from './components/trip-info-form/trip-info-form.component';
import { UnapprovedProductEvaluationsComponent } from './components/unapproved-product-evaluations/unapproved-product-evaluations.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { VendorProfilesComponent } from './components/vendor-profiles/vendor-profiles.component';
import { ViewPdfComponent } from './components/view-pdf/view-pdf.component';
import { VisitInfoViewComponent } from './components/visit-info-view/visit-info-view.component';
import { CustomDirectivesModule } from './directives/custom-directives.module';
import { ApplicationProgressCellRendererComponent } from './grid-components/application-progress-cell-renderer/application-progress-cell-renderer.component';
import { AttachmentsCellRendererComponent } from './grid-components/attachments-cell-renderer/attachments-cell-renderer.component';
import { BooleanCellRendererComponent } from './grid-components/boolean-cell-renderer/boolean-cell-renderer.component';
import { BooleanColumnFilterRendererComponent } from './grid-components/boolean-column-filter-renderer/boolean-column-filter-renderer.component';
import { ButtonCellRendererComponent } from './grid-components/button-cell-renderer/button-cell-renderer.component';
import { CheckCellRendererComponent } from './grid-components/check-cell-renderer/check-cell-renderer.component';
import { CheckHeaderRendererComponent } from './grid-components/check-header-renderer/check-header-renderer.component';
import { CountryCellRendererComponent } from './grid-components/country-cell-renderer/country-cell-renderer.component';
import { DateColumnFilterRendererComponent } from './grid-components/date-column-filter-renderer/date-column-filter-renderer.component';
import { DropdownCellRendererComponent } from './grid-components/dropdown-cell-renderer/dropdown-cell-renderer.component';
import { FeeTextInputCellRendererComponent } from './grid-components/fee-text-input-cell-renderer/fee-text-input-cell-renderer.component';
import { HtmlCellRendererComponent } from './grid-components/html-cell-renderer/html-cell-renderer.component';
import { InvoicingGroupCellRendererComponent } from './grid-components/invoicing-group-cell-renderer/invoicing-group-cell-renderer.component';
import { NoRowsComponent } from './grid-components/no-rows/no-rows.component';
import { NoteCellRendererComponent } from './grid-components/note-cell-renderer/note-cell-renderer.component';
import { PermissionsHeaderComponent } from './grid-components/permissions-header/permissions-header.component';
import { StatusCellRendererComponent } from './grid-components/status-cell-renderer/status-cell-renderer.component';
import { TextCellRendererComponent } from './grid-components/text-cell-renderer/text-cell-renderer.component';
import { TextColumnFilterRendererComponent } from './grid-components/text-column-filter-renderer/text-column-filter-renderer.component';
import { TextInputCellRendererComponent } from './grid-components/text-input-cell-renderer/text-input-cell-renderer.component';
import { TextInputCheckboxCellRendererComponent } from './grid-components/text-input-checkbox-cell-renderer/text-input-checkbox-cell-renderer.component';
import { TextLabelCheckboxCellRendererComponent } from './grid-components/text-label-checkbox-cell-renderer/text-label-checkbox-cell-renderer.component';
import { TitleCheckboxLabelHeaderRendererComponent } from './grid-components/title-checkbox-label-header-renderer/title-checkbox-label-header-renderer.component';
import { TooltipRendererComponent } from './grid-components/tooltip-renderer/tooltip-renderer.component';
import { UndoCellRendererComponent } from './grid-components/undo-cell-renderer/undo-cell-renderer.component';
import { ViewCellRendererComponent } from './grid-components/view-cell-renderer/view-cell-renderer.component';
import { IconsModule } from './icons/icons.module';
import { AddressTextInputComponent } from './inputs/address-text-input/address-text-input.component';
import { CheckboxComponent } from './inputs/checkbox/checkbox.component';
import { CurrencyAmountComponent } from './inputs/currency-amount/currency-amount.component';
import { DatePickerInputComponent } from './inputs/date-picker-input/date-picker-input.component';
import { DurationPickerComponent } from './inputs/duration-picker/duration-picker.component';
import { GridSearchInputComponent } from './inputs/grid-search-input/grid-search-input.component';
import { InputErrorMessagesComponent } from './inputs/input-error-messages/input-error-messages.component';
import { LookUpInputComponent } from './inputs/look-up-input/look-up-input.component';
import { NoteFormComponent } from './inputs/note-form/note-form.component';
import { PaymentMethodInputComponent } from './inputs/payment-method-input/payment-method-input.component';
import { PickNoteInputComponent } from './inputs/pick-note-input/pick-note-input.component';
import { RadioButtonsListComponent } from './inputs/radio-buttons-list/radio-buttons-list.component';
import { SearchInputComponent } from './inputs/search-input/search-input.component';
import { SelectInputComponent } from './inputs/select-input/select-input.component';
import { SelectMultipleInputComponent } from './inputs/select-multiple-input/select-multiple-input.component';
import { SelectWithSearchInputComponent } from './inputs/select-with-search-input/select-with-search-input.component';
import { TaskEntitySearchComponent } from './inputs/task-entity-search/task-entity-search.component';
import { TextEditorComponent } from './inputs/text-editor/text-editor.component';
import { TextInputComponent } from './inputs/text-input/text-input.component';
import { TextareaInputComponent } from './inputs/textarea-input/textarea-input.component';
import { UploadFileComponent } from './inputs/upload-file/upload-file.component';
import { AddAgencyComponent } from './modals/add-agency/add-agency.component';
import { AddBrandComponent } from './modals/add-brand/add-brand.component';
import { AddCategoryModalComponent } from './modals/add-category-modal/add-category-modal.component';
import { AddCertficateItemComponent } from './modals/add-certficate-item/add-certficate-item.component';
import { AddCertificateKcGroupsComponent } from './modals/add-certificate-kc-groups/add-certificate-kc-groups.component';
import { AddCodeComponent } from './modals/add-code/add-code.component';
import { AddContactComponent } from './modals/add-contact/add-contact.component';
import { AddCustomFeeModalComponent } from './modals/add-custom-fee-modal/add-custom-fee-modal.component';
import { AddDifferentLotBatchComponent } from './modals/add-different-lot-batch/add-different-lot-batch.component';
import { AddExpenseComponent } from './modals/add-expense/add-expense.component';
import { AddFacilityInfoComponent } from './modals/add-facility-info/add-facility-info.component';
import { AddFacilityComponent } from './modals/add-facility/add-facility.component';
import { ViewTermAuditComponent } from './modals/view-term-audit/view-term-audit.component';
import { AddGroupComponent } from './modals/add-group/add-group.component';
import { AddKcgroupComponent } from './modals/add-kcgroup/add-kcgroup.component';
import { AddLinkOptionComponent } from './modals/add-link-option/add-link-option.component';
import { AddMasterIngredientComponent } from './modals/add-master-ingredient/add-master-ingredient.component';
import { AddMemberComponent } from './modals/add-member/add-member.component';
import { AddNoteComponent } from './modals/add-note/add-note.component';
import { AddOwnerProcessModalComponent } from './modals/add-owner-process-modal/add-owner-process-modal.component';
import { AddPrivateLabelerInfoComponent } from './modals/add-private-labeler-info/add-private-labeler-info.component';
import { AddProductLabelComponent } from './modals/add-product-label/add-product-label.component';
import { AddProductModalComponent } from './modals/add-product-modal/add-product-modal.component';
import { SameAsProductFormComponent } from './modals/add-product-modal/same-as-product-form/same-as-product-form.component';
import { AddProfileQuestionComponent } from './modals/add-profile-question/add-profile-question.component';
import { FormAnswerTypeRendererComponent } from './modals/add-profile-question/form-answer-type-renderer/form-answer-type-renderer.component';
import { AddPurchaseHistoryComponent } from './modals/add-purchase-history/add-purchase-history.component';
import { AddRoleComponent } from './modals/add-role/add-role.component';
import { AddSameProductLotBatchComponent } from './modals/add-same-product-lot-batch/add-same-product-lot-batch.component';
import { AddScriptComponent } from './modals/add-script/add-script.component';
import { AddSecondaryRoutingComponent } from './modals/add-secondary-routing/add-secondary-routing.component';
import { AddSignedDateCertificateComponent } from './modals/add-signed-date-certificate/add-signed-date-certificate.component';
import { AddTaskComponent } from './modals/add-task/add-task.component';
import { AddTripComponent } from './modals/add-trip/add-trip.component';
import { AddUserComponent } from './modals/add-user/add-user.component';
import { AddVendorComponent } from './modals/add-vendor/add-vendor.component';
import { AddVisitsComponent } from './modals/add-visits/add-visits.component';
import { AdditionalFeesInvoicesComponent } from './modals/additional-fees-invoices/additional-fees-invoices.component';
import { BusinessEntityCompareInfoComponent } from './modals/business-entity-compare-info/business-entity-compare-info.component';
import { CompanyInfoComponent } from './modals/business-entity-compare-info/company-info/company-info.component';
import { CompareAddressItemComponent } from './modals/business-entity-compare-info/compare-items/compare-address-item/compare-address-item.component';
import { CompareNotificationSettingItemComponent } from './modals/business-entity-compare-info/compare-items/compare-notification-setting-item/compare-notification-setting-item.component';
import { CompareStringItemComponent } from './modals/business-entity-compare-info/compare-items/compare-string-item/compare-string-item.component';
import { ContactInfoComponent } from './modals/business-entity-compare-info/contact-info/contact-info.component';
import { FacilityInfoComponent } from './modals/business-entity-compare-info/facility-info/facility-info.component';
import { PrivateLabelerContractInfoComponent } from './modals/business-entity-compare-info/private-labeler-contract-info/private-labeler-contract-info.component';
import { PrivateLabelerInfoComponent } from './modals/business-entity-compare-info/private-labeler-info/private-labeler-info.component';
import { CertificatePreviewModalComponent } from './modals/certificate-preview-modal/certificate-preview-modal.component';
import { ChangeSecurityLevelModalComponent } from './modals/change-security-level-modal/change-security-level-modal.component';
import { CommunicationComponent } from './modals/communication/communication.component';
import { ConfirmComponent } from './modals/confirm/confirm.component';
import { CreateKcgroupComponent } from './modals/create-kcgroup/create-kcgroup.component';
import { DataLossModalComponent } from './modals/data-loss-modal/data-loss-modal.component';
import { FacilityFormulaIngredientUtilityComponent } from './modals/facility-formula-ingredient-utility/facility-formula-ingredient-utility.component';
import { FormulaReportComponent } from './modals/formula-report/formula-report.component';
import { GenerateCreditMemoComponent } from './modals/generate-credit-memo/generate-credit-memo.component';
import { GenericGridSearchModalComponent } from './modals/generic-grid-search-modal/generic-grid-search-modal.component';
import { GridWrapperComponent } from './modals/generic-grid-search-modal/grid-wrapper/grid-wrapper.component';
import { SelectionOverviewComponent } from './modals/generic-grid-search-modal/selection-overview/selection-overview.component';
import { GenericValidationModalComponent } from './modals/generic-validation-modal/generic-validation-modal.component';
import { GenericViewChangesModalComponent } from './modals/generic-view-changes-modal/generic-view-changes-modal.component';
import { GlobalSearchComponent } from './modals/global-search/global-search.component';
import { IdleComponent } from './modals/idle/idle.component';
import { InvoiceGenerationErrorsComponent } from './modals/invoice-generation-errors/invoice-generation-errors.component';
import { LinkCategoryModalComponent } from './modals/link-category-modal/link-category-modal.component';
import { LinkCompanyFacilityComponent } from './modals/link-company-facility/link-company-facility.component';
import { LinkEntityComponent } from './modals/link-entity/link-entity.component';
import { LinkTaskModalComponent } from './modals/link-task-modal/link-task-modal.component';
import { MergeIngredientsComponent } from './modals/merge-ingredients/merge-ingredients.component';
import { PreviewInvoiceComponent } from './modals/preview-invoice/preview-invoice.component';
import { ProcessBatchIngredientErrorsModalComponent } from './modals/process-batch-ingredient-errors-modal/process-batch-ingredient-errors-modal.component';
import { ProductNameValidationComponent } from './modals/product-name-validation/product-name-validation.component';
import { PublishCertificateComponent } from './modals/publish-certificate/publish-certificate.component';
import { QcChecklistComponent } from './modals/qc-checklist/qc-checklist.component';
import { ReferralInformationComponent } from './modals/referral-information/referral-information.component';
import { RunScriptComponent } from './modals/run-script/run-script.component';
import { ScriptHistoryComponent } from './modals/script-history/script-history.component';
import { SearchEntityComponent } from './modals/search-entity/search-entity.component';
import { SelectTypeAddVisitComponent } from './modals/select-type-add-visit/select-type-add-visit.component';
import { ShowNotificationErrorsComponent } from './modals/show-notification-errors/show-notification-errors.component';
import { UpdatePoComponent } from './modals/update-po/update-po.component';
import { UploadAmexExpensesTripComponent } from './modals/upload-amex-expenses-trip/upload-amex-expenses-trip.component';
import { UploadAmexExpensesComponent } from './modals/upload-amex-expenses/upload-amex-expenses.component';
import { UploadBatchIngredientsFileComponent } from './modals/upload-batch-ingredients-file/upload-batch-ingredients-file.component';
import { UploadBatchProductsFileComponent } from './modals/upload-batch-products-file/upload-batch-products-file.component';
import { UploadFormulaItemsComponent } from './modals/upload-formula-items/upload-formula-items.component';
import { ViewAuditTrailModalComponent } from './modals/view-audit-trail-modal/view-audit-trail-modal.component';
import { ViewScheduleComponent } from './modals/view-check-in-check-out/view-schedule.component';
import { ViewEntityProfileComponent } from './modals/view-entity-profile/view-entity-profile.component';
import { ViewFacilityContractNotesComponent } from './modals/view-facility-contract-notes/view-facility-contract-notes.component';
import { ViewGridSelectionComponent } from './modals/view-grid-selection/view-grid-selection.component';
import { ViewOutstandingInvoicesComponent } from './modals/view-outstanding-invoices/view-outstanding-invoices.component';
import { VoidEvaluationModalComponent } from './modals/void-evaluation-modal/void-evaluation-modal.component';
import { MaterialModule } from './modules/material.module';
import { ArrayLenghtIsPipe } from './pipes/array-lenght-is.pipe';
import { AuditPipe } from './pipes/audit.pipe';
import { CamelcaseDisplayPipe } from './pipes/camelcase-display.pipe';
import { ConditionalValuePipe } from './pipes/conditional-value.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { OptionPipe } from './pipes/option.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PurifyPipe } from './pipes/purify.pipe';
import { RenderValueFromListPipe } from './pipes/render-list-value.pipe';
import { DataLossPreventionGuard } from './route-guards/data-loss-prevention.route-guard';
import { UserPermissionGuard } from './route-guards/user-permission.route-guard';
import { AccountingRenewalListComponent } from './shared-grids/accounting-renewal/accounting-renewal-list.component';
import { AliasListComponent } from './shared-grids/alias-list/alias-list.component';
import { AmexFileItemsListComponent } from './shared-grids/amex-file-items-list/amex-file-items-list.component';
import { AmexListComponent } from './shared-grids/amex-list/amex-list.component';
import { AuditTrailListComponent } from './shared-grids/audit-trail-list/audit-trail-list.component';
import { BrandsListComponent } from './shared-grids/brands-list/brands-list.component';
import { ChangedMasterIngredientsListComponent } from './shared-grids/changed-master-ingredients-list/changed-master-ingredients-list.component';
import { ChangesHistoryListComponent } from './shared-grids/changes-history-list/changes-history-list.component';
import { CompaniesListComponent } from './shared-grids/companies-list/companies-list.component';
import { CompanyFacilityStatusListComponent } from './shared-grids/company-facility-status-list/company-facility-status-list.component';
import { CompanyPrivateLabelerStatusListComponent } from './shared-grids/company-private-labeler-status-list/company-private-labeler-status-list.component';
import { CompanyProductsListComponent } from './shared-grids/company-products-list/company-products-list.component';
import { CompareListComponent } from './shared-grids/compare-list/compare-list.component';
import { CustomerIngredientsListComponent } from './shared-grids/customer-ingredients-list/customer-ingredients-list.component';
import { EntityFacilitiesListComponent } from './shared-grids/entity-facilities-list/entity-facilities-list.component';
import { ExpensesListComponent } from './shared-grids/expenses-list/expenses-list.component';
import { ExpensesTableComponent } from './shared-grids/expenses-list/expenses-table/expenses-table.component';
import { VisitExpenseCellComponent } from './shared-grids/expenses-list/expenses-table/trip-expenses-cells/visit-expense-cell/visit-expense-cell.component';
import { VisitExpenseInfoComponent } from './shared-grids/expenses-list/expenses-table/trip-expenses-cells/visit-expense-info/visit-expense-info.component';
import { VisitExpenseTotalCellComponent } from './shared-grids/expenses-list/expenses-table/trip-expenses-cells/visit-expense-total-cell/visit-expense-total-cell.component';
import { VisitFeeCellFooterComponent } from './shared-grids/expenses-list/expenses-table/trip-expenses-cells/visit-fee-cell-footer/visit-fee-cell-footer.component';
import { VisitFeeCellComponent } from './shared-grids/expenses-list/expenses-table/trip-expenses-cells/visit-fee-cell/visit-fee-cell.component';
import { VisitTotalCellComponent } from './shared-grids/expenses-list/expenses-table/trip-expenses-cells/visit-total-cell/visit-total-cell.component';
import { SplitExpensesModalComponent } from './shared-grids/expenses-list/split-expenses-modal/split-expenses-modal.component';
import { FacilitiesListComponent } from './shared-grids/facilities-list/facilities-list.component';
import { FacilityCompanyStatusListComponent } from './shared-grids/facility-company-status-list/facility-company-status-list.component';
import { GenericGridComponent } from './shared-grids/generic-grid/generic-grid.component';
import { GenericSearchGridComponent } from './shared-grids/generic-search-grid/generic-search-grid.component';
import { IncompleteApplicationsListComponent } from './shared-grids/incomplete-applications-list/incomplete-applications-list.component';
import { IngredientBatchListComponent } from './shared-grids/ingredient-batch-list/ingredient-batch-list.component';
import { InvoicesListComponent } from './shared-grids/invoices-list/invoices-list.component';
import { KosherCertificatesListComponent } from './shared-grids/kosher-certificates-list/kosher-certificates-list.component';
import { MasterIngredientsListComponent } from './shared-grids/master-ingredients-list/master-ingredients-list.component';
import { NotesListComponent } from './shared-grids/notes-list/notes-list.component';
import { PrivateLabelersListComponent } from './shared-grids/private-labelers-list/private-labelers-list.component';
import { ProcessesListComponent } from './shared-grids/processes-list/processes-list.component';
import { ProductCategoriesListComponent } from './shared-grids/product-categories-list/product-categories-list.component';
import { ProductPublicationListComponent } from './shared-grids/product-publication-list/product-publication-list.component';
import { ProductsListComponent } from './shared-grids/products-list/products-list.component';
import { ReviewIngredientsListComponent } from './shared-grids/review-ingredients-list/review-ingredients-list.component';
import { SearchIngredientTabComponent } from './shared-grids/search-ingredient-tab/search-ingredient-tab.component';
import { TasksListComponent } from './shared-grids/tasks-list/tasks-list.component';
import { TransactionsListComponent } from './shared-grids/transactions-list/transactions-list.component';
import { TripListComponent } from './shared-grids/trip-list/trip-list.component';
import { UkdSearchListComponent } from './shared-grids/ukd-search-list/ukd-search-list.component';
import { VendorsListComponent } from './shared-grids/vendors-list/vendors-list.component';
import { VisitsListComponent } from './shared-grids/visits-list/visits-list.component';
import { UploadCodeChangeUtilityComponent } from './modals/upload-code-change-utility/upload-code-change-utility.component';
import { GenericUploadFileSummaryComponent } from './components/generic-upload-file-summary/generic-upload-file-summary.component';
import { DiscrepanciesComponent } from './components/discrepancies/discrepancies.component';
import { AddDefaultValueComponent } from './modals/add-default-value/add-default-value.component';
import { AddCompanyInfoComponent } from './modals/add-company-info/add-company-info.component';
import { AddContractItemComponent } from './components/facility-contract/facility-contract-fees-and-expenses/add-contract-item/add-contract-item.component';
import { FacilityContractTermListComponent } from './components/facility-contract/facility-contract-term-list/facility-contract-term-list.component';
import { AdvancedPermissionsListComponent } from './components/advanced-permissions-list/advanced-permissions-list.component';
import { UploadTripExpenseComponent } from './modals/upload-trip-expense/upload-trip-expense.component';
import { RightClickMenuComponent } from './components/right-click-menu/right-click-menu.component';
import { AddTripExpenseAssigneeComponent } from './modals/add-trip-expense-assignee/add-trip-expense-assignee.component';
import { FieldRepExpensesComponent } from '../modules/trips/field-rep-expenses/field-rep-expenses.component';
import { AddTermComponent } from './modals/add-term/add-term.component';
import { GroupTermFormComponent } from './modals/add-term/group-term-form/group-term-form.component';
import { SingleTermFormComponent } from './modals/add-term/single-term-form/single-term-form.component';
import { TermBulkUpdateComponent } from './modals/add-term/term-bulk-update/term-bulk-update.component';
import { TermInfoFormComponent } from './modals/add-term/term-info-form/term-info-form.component';
import { PrivateLabelerInactivateFormComponent } from './components/private-labeler-inactivate-form/private-labeler-inactivate-form.component';
import { ProratedMonthlyFeeCellRenderComponent } from './grid-components/prorated-monthly-fee-cell-render/prorated-monthly-fee-cell-render.component';
import { PrivateLabelerCompanyStatusListComponent } from './shared-grids/private-labeler-company-status-list/private-labeler-company-status-list.component';
import { PrivateLabelerActivateFormComponent } from './components/private-labeler-activate-form/private-labeler-activate-form.component';
import { ChangeFacilityOwnerComponent } from './modals/change-facility-owner/change-facility-owner.component';
import { AddTaskCommunicationComponent } from './modals/add-task-communication/add-task-communication.component';
import { CompanyRenewalFacilityListComponent } from './components/renewal-info/company-renewal-facility-list/company-renewal-facility-list.component';
import { EditAssignmentComponent } from './modals/edit-assignment-modal/edit-assignment-modal.component';
import { CompanyRenewalFacilityHistoryListComponent } from './components/renewal-info/company-renewal-facility-history-list/company-renewal-facility-history-list.component';
import { UploadAmexExpensesGridComponent } from './modals/upload-amex-expenses-trip/upload-amex-expenses-grid/upload-amex-expenses-grid.component';
import { DownloadInvoiceRendererComponent } from './grid-components/download-button-renderer/download-invoice-renderer.component';
import { CompanyRenewalPrivateLabelerListComponent } from './components/renewal-info/company-renewal-private-labeler-list/company-renewal-private-labeler-list.component';
import { GenerateInvoicesListComponent } from './shared-grids/generate-invoices-list/generate-invoices-list.component';
import { AccountingLogSideInfoComponent } from './components/sideinfo-components/accounting-log-side-info/accounting-log-side-info.component';
import { PreviewInvoiceApTableComponent } from './modals/preview-invoice/ap-table/ap-table.component';
import { PreviewInvoiceApTableVisitsComponent } from './modals/preview-invoice/ap-table-visits/ap-table-visits.component';
import { VisitInactivateFormComponent } from './components/visit-inactivate-form/visit-inactivate-form.component';
import { ApOrArNumberListRendererComponent } from './grid-components/ap-or-ar-numbers-renderer/ap-or-ar-numbers-renderer.component';
import { GroupCheckCellRendererComponent } from './grid-components/check-cell-renderer/group-check-cell-renderer.component';
import { VisitsExpensesAlreadyInvoicedComponent } from './modals/visits-expenses-already-invoiced/visits-expenses-already-invoiced.component';
import { ReadonlyCheckCellRendererComponent } from './grid-components/check-cell-renderer/readonly-check-cell-renderer.component';
import { AcumaticaApOrArNumberListRendererComponent } from './grid-components/acumatica-ap-or-ar-number-list-renderer/acumatica-ap-or-ar-number-list-renderer.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
};

@NgModule({
	declarations: [
		SidenavComponent,
		HeaderComponent,
		SideinfoComponent,
		TextInputComponent,
		SelectInputComponent,
		TabsMenuComponent,
		UserMenuComponent,
		CountryCellRendererComponent,
		BooleanCellRendererComponent,
		RadioButtonsListComponent,
		TextareaInputComponent,
		PageTitleComponent,
		CheckboxComponent,
		SearchBarComponent,
		FilterPipe,
		NoSanitizePipe,
		DateTimePipe,
		NoRowsComponent,
		AddressTextInputComponent,
		ConfirmComponent,
		FacilitiesListComponent,
		CompaniesListComponent,
		TasksListComponent,
		PrivateLabelersListComponent,
		BrandsListComponent,
		AliasListComponent,
		ContactSideinfoComponent,
		EntityFormComponent,
		CurrentContractPageComponent,
		AddressFormComponent,
		NoteFormComponent,
		UploadFileComponent,
		RolesFormComponent,
		CheckCellRendererComponent,
		GroupCheckCellRendererComponent,
		NotesListComponent,
		PermissionsHeaderComponent,
		RoleSideinfoComponent,
		AddRoleComponent,
		BrandSideinfoComponent,
		AccountingLogSideInfoComponent,
		AddBrandComponent,
		NoteSideInfoComponent,
		ReadMoreComponent,
		AddNoteComponent,
		ProfileFormComponent,
		CompanyInfoFormComponent,
		ReferralFormComponent,
		FacilityInfoFormComponent,
		ContactInfoFormComponent,
		CurrencyAmountComponent,
		ApplicationSideinfoComponent,
		SideinfoFacilityComponent,
		FacilitiesAppFormComponent,
		AddFacilityComponent,
		SummaryFieldListComponent,
		SummaryFieldWithLabelComponent,
		ContactsAppFormComponent,
		CompanyApplicationSideinfoComponent,
		PrivateLabelerApplicationSideinfoComponent,
		ContactsAppFormComponent,
		AccordionComponent,
		AccordionGroupComponent,
		ContractFormComponent,
		InvoiceSettingsFormComponent,
		CompanyContractAppFormComponent,
		CompanyAppFormComponent,
		InvoicingGroupsAppFormComponent,
		BusinessEntityCompareInfoComponent,
		CompanyInfoComponent,
		FacilityInfoComponent,
		ContactInfoComponent,
		BusinessEntitySearchComponent,
		CompanyAppFormComponent,
		FeesAndExpensesAddFormComponent,
		FeesAndExpensesInputFormComponent,
		LinkEntityComponent,
		CompanySideinfoComponent,
		FacilityFormComponent,
		AddLinkOptionComponent,
		VisitsListComponent,
		AddVisitsComponent,
		VisitSideinfoComponent,
		HistorySideinfoComponent,
		AddContactComponent,
		InitialVisitAppFormComponent,
		FeesAndExpensesTableComponent,
		ViewScheduleComponent,
		TripListComponent,
		TripSideinfoComponent,
		SelectTypeAddVisitComponent,
		TripInfoFormComponent,
		AddTripComponent,
		AddTaskComponent,
		TaskSideinfoComponent,
		VisitInfoViewComponent,
		CommunicationChatComponent,
		CompanyFacilityStatusListComponent,
		CompanyPrivateLabelerStatusListComponent,
		CompanyInactivateFormComponent,
		TransactionsListComponent,
		CompanyActivateFormComponent,
		PrivateLabelerAppFormComponent,
		ApplicationPageHeaderComponent,
		FacilityInactivateFormComponent,
		FacilityCompanyStatusListComponent,
		FacilitySideinfoComponent,
		FacilityActivateFormComponent,
		AddFacilityInfoComponent,
		CompareStringItemComponent,
		CompareAddressItemComponent,
		CompareNotificationSettingItemComponent,
		PrivateLabelerSideinfoComponent,
		PrivateLabelerInfoComponent,
		PrivateLabelerContractInfoComponent,
		AddPrivateLabelerInfoComponent,
		PrivateLabelerInfoFormComponent,
		SearchEntityComponent,
		RestaurantApplicationSideinfoComponent,
		TaskInfoFormComponent,
		TaskControlsFormComponent,
		DatePickerInputComponent,
		PrivateLabelerRelatedFeesComponent,
		TextInputCellRendererComponent,
		TextInputCheckboxCellRendererComponent,
		FeeTextInputCellRendererComponent,
		TitleCheckboxLabelHeaderRendererComponent,
		DropdownCellRendererComponent,
		NoteCellRendererComponent,
		TaskEntitySearchComponent,
		ExpensesListComponent,
		AddExpenseComponent,
		GroupSideinfoComponent,
		AddGroupComponent,
		AddMemberComponent,
		AddUserComponent,
		MasterIngredientsListComponent,
		SearchInputComponent,
		AmexListComponent,
		UploadAmexExpensesComponent,
		UploadAmexExpensesTripComponent,
		UploadAmexExpensesGridComponent,
		MasterIngredientsSideinfoComponent,
		AmexFileItemsListComponent,
		CustomerIngredientsListComponent,
		ViewCellRendererComponent,
		TextCellRendererComponent,
		IngredientCertificateAttributeComponent,
		MasterIngredientGeneralInfoComponent,
		MasterIngredientKosherInfoComponent,
		MasterIngredientKosherEvaluationComponent,
		PackagingComponent,
		MasterIngredientCurrentCertificateComponent,
		CustomerIngredientSideinfoComponent,
		GenericGridSearchModalComponent,
		AddVendorComponent,
		AddAgencyComponent,
		GridWrapperComponent,
		CustomerIngredientGeneralInfoComponent,
		CustomerIngredientsMasterIngredientComponent,
		IngredientBatchListComponent,
		IngredientBatchSideinfoComponent,
		ViewEntityProfileComponent,
		AddAdditionalFeeComponent,
		UploadBatchIngredientsFileComponent,
		AuditPipe,
		CamelcaseDisplayPipe,
		OptionPipe,
		HistoryDisplayItemComponent,
		InvoicesTabComponent,
		SelectionOverviewComponent,
		SelectMultipleInputComponent,
		SplitExpensesModalComponent,
		DynamicMenuComponent,
		QcChecklistComponent,
		ProductSideinfoComponent,
		ProductsListComponent,
		ExpensesTableComponent,
		FacilityContractSummaryComponent,
		PreviewInvoiceComponent,
		PreviewInvoiceApTableComponent,
		PreviewInvoiceApTableVisitsComponent,
		AddMasterIngredientComponent,
		EntityFacilitiesListComponent,
		MergeIngredientsComponent,
		SearchIngredientTabComponent,
		MergeIngredientTabComponent,
		CompareListComponent,
		UndoCellRendererComponent,
		MergeIngredientMasterIngredientItemComponent,
		GenericViewChangesModalComponent,
		KosherCertificatesListComponent,
		AddProfileQuestionComponent,
		FormAnswerTypeRendererComponent,
		AddProductLabelComponent,
		KcGroupComponent,
		AccountingRenewalListComponent,
		ChangesHistoryListComponent,
		AddKcgroupComponent,
		GenericValidationModalComponent,
		DurationPickerComponent,
		CertificatePreviewModalComponent,
		TextEditorComponent,
		InvoicesListComponent,
		DocumentTemplateComponent,
		GenerateCreditMemoComponent,
		PickNoteInputComponent,
		InvoiceSideinfoComponent,
		ReviewIngredientsListComponent,
		UpdatePoComponent,
		GenerateContractComponent,
		VisitExpenseInfoComponent,
		VisitFeeCellComponent,
		VisitExpenseCellComponent,
		VisitTotalCellComponent,
		VisitFeeCellFooterComponent,
		VisitExpenseTotalCellComponent,
		ViewTermAuditComponent,
		PaymentMethodInputComponent,
		UploadBatchProductsFileComponent,
		SelectWithSearchInputComponent,
		InvoiceGenerationErrorsComponent,
		GenericSearchGridComponent,
		NotificationSideinfoComponent,
		ViewPdfComponent,
		InputErrorMessagesComponent,
		NoteListComponent,
		RenewalInfoComponent,
		AccountingRenewalTableComponent,
		EntityProfileAsweredQuestionsListComponent,
		MagicReviewSideInfoComponent,
		IngredientFacilitiesComponent,
		OrderByPipe,
		AddCompanyInfoComponent,
		ProductCategoriesListComponent,
		LinkCategoryModalComponent,
		CheckHeaderRendererComponent,
		CompanyProductsListComponent,
		ChangedMasterIngredientsListComponent,
		HtmlCellRendererComponent,
		ProcessBatchIngredientErrorsModalComponent,
		ArrayLenghtIsPipe,
		CompanyRenewalHistoryListComponent,
		ViewOutstandingInvoicesComponent,
		CompanySnapshotsSideInfoComponent,
		KosherCertificatesFormComponent,
		AddSecondaryRoutingComponent,
		PrivateLabelerContractAppFormComponent,
		PrivateLabelerContractComponent,
		AddCertficateItemComponent,
		PlCurrentContractPageComponent,
		CreateKcgroupComponent,
		GenericGridComponent,
		ProductsListComponent,
		UploadFormulaItemsComponent,
		FormulaReportComponent,
		AddCertificateKcGroupsComponent,
		AddSignedDateCertificateComponent,
		PublishCertificateComponent,
		CommunicationComponent,
		ReferralInformationComponent,
		EditAssignmentComponent,
		InvoicingGroupCellRendererComponent,
		TextLabelCheckboxCellRendererComponent,
		VendorsListComponent,
		AddSameProductLotBatchComponent,
		AddDifferentLotBatchComponent,
		AdditionalFeesInvoicesComponent,
		IdleComponent,
		DataLossModalComponent,
		LookUpInputComponent,
		ProfileTemplatesComponent,
		ProfileTemplateItemComponent,
		ViewFacilityContractNotesComponent,
		IncompleteApplicationsListComponent,
		ButtonCellRendererComponent,
		GlobalSearchComponent,
		ShowNotificationErrorsComponent,
		SummaryFieldIconComponent,
		SummaryFieldTextComponent,
		BooleanColumnFilterRendererComponent,
		ApplicationProgressCellRendererComponent,
		TextColumnFilterRendererComponent,
		PermissionsListComponent,
		UkdSearchListComponent,
		AddScriptComponent,
		StaticBarComponent,
		GridSearchInputComponent,
		VendorProfilesComponent,
		StatusCellRendererComponent,
		DownloadInvoiceRendererComponent,
		IngredientBatchListComponent,
		AttachmentsCellRendererComponent,
		PurifyPipe,
		ProcessesListComponent,
		AddOwnerProcessModalComponent,
		AddProductModalComponent,
		AuditTrailListComponent,
		ViewAuditTrailModalComponent,
		SameAsProductFormComponent,
		RunScriptComponent,
		ProductNameValidationComponent,
		ScriptHistoryComponent,
		ProductReviewQueueSideInfoComponent,
		UnapprovedProductEvaluationsComponent,
		InternalProductsRequiringApprovalComponent,
		EntityFormLineItemComponent,
		LinkTaskModalComponent,
		ProductFormulaReportComponent,
		AddCategoryModalComponent,
		ProductPublicationListComponent,
		ChangeSecurityLevelModalComponent,
		VoidEvaluationModalComponent,
		ViewGridSelectionComponent,
		TooltipRendererComponent,
		FacilityFormulaIngredientUtilityComponent,
		ScriptsListComponent,
		AddPurchaseHistoryComponent,
		DateColumnFilterRendererComponent,
		EntityGuidelinesComponent,
		LinkCompanyFacilityComponent,
		FacilityContractComponent,
		PageHeaderComponent,
		FacilityContractDetailsComponent,
		FacilityContractVisitFeesComponent,
		FacilityContractTermsComponent,
		AddCustomFeeModalComponent,
		ConditionalValuePipe,
		VisitFeesRowComponent,
		TermExpenseRowComponent,
		RenderValueFromListPipe,
		AddCodeComponent,
		UploadCodeChangeUtilityComponent,
		GenericUploadFileSummaryComponent,
		DiscrepanciesComponent,
		AddDefaultValueComponent,
		AddContractItemComponent,
		FacilityContractTermListComponent,
		AdvancedPermissionsListComponent,
		UploadTripExpenseComponent,
		RightClickMenuComponent,
		AddTripExpenseAssigneeComponent,
		FieldRepExpensesComponent,
		AddTermComponent,
		TermBulkUpdateComponent,
		TermInfoFormComponent,
		GroupTermFormComponent,
		SingleTermFormComponent,
		MergeBusinessEntityGridHeaderComponent,
		AddContactToNotificationWhitelistComponent,
		PrivateLabelerInactivateFormComponent,
		ProratedMonthlyFeeCellRenderComponent,
		PrivateLabelerCompanyStatusListComponent,
		PrivateLabelerActivateFormComponent,
		ChangeFacilityOwnerComponent,
		AddTaskCommunicationComponent,
		CompanyRenewalFacilityListComponent,
		CompanyRenewalFacilityHistoryListComponent,
		UploadAmexExpensesGridComponent,
		CompanyRenewalPrivateLabelerListComponent,
		GenerateInvoicesListComponent,
		VisitInactivateFormComponent,
		ApOrArNumberListRendererComponent,
		VisitsExpensesAlreadyInvoicedComponent,
		ReadonlyCheckCellRendererComponent,
		AcumaticaApOrArNumberListRendererComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		AppStoreModule,
		IconsModule,
		CustomDirectivesModule,
		FroalaEditorModule.forRoot(),
		FroalaViewModule.forRoot(),
		TabsModule,
		AgGridModule.withComponents([
			CountryCellRendererComponent,
			BooleanCellRendererComponent,
			CheckCellRendererComponent,
			GroupCheckCellRendererComponent,
			TooltipRendererComponent,
			CheckHeaderRendererComponent,
			TextInputCellRendererComponent,
			TextInputCheckboxCellRendererComponent,
			DropdownCellRendererComponent,
			ViewCellRendererComponent,
			NoteCellRendererComponent,
			TitleCheckboxLabelHeaderRendererComponent,
			TextCellRendererComponent,
			FeeTextInputCellRendererComponent,
			UndoCellRendererComponent,
			PermissionsHeaderComponent,
			DownloadInvoiceRendererComponent,
			ReadonlyCheckCellRendererComponent,
		]),
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		NgSelectModule,
		MaterialModule,
		NgxExtendedPdfViewerModule,
		A11yModule,
	],
	exports: [
		MaterialModule,
		IconsModule,
		CustomDirectivesModule,
		TabsModule,
		PerfectScrollbarModule,
		AgGridModule,
		SidenavComponent,
		HeaderComponent,
		SideinfoComponent,
		UnapprovedProductEvaluationsComponent,
		GridSearchInputComponent,
		TextInputComponent,
		TextareaInputComponent,
		SelectWithSearchInputComponent,
		SelectInputComponent,
		TabsMenuComponent,
		RadioButtonsListComponent,
		ProductsListComponent,
		CheckboxComponent,
		ProfileTemplatesComponent,
		ProfileTemplateItemComponent,
		SearchBarComponent,
		FilterPipe,
		DateTimePipe,
		NoRowsComponent,
		AddressTextInputComponent,
		ConfirmComponent,
		FacilitiesListComponent,
		CompaniesListComponent,
		TasksListComponent,
		BrandsListComponent,
		AliasListComponent,
		AddressFormComponent,
		NoteFormComponent,
		UploadFileComponent,
		RolesFormComponent,
		CheckCellRendererComponent,
		GroupCheckCellRendererComponent,
		TooltipRendererComponent,
		CheckHeaderRendererComponent,
		ProfileFormComponent,
		CompanyInfoFormComponent,
		ReferralFormComponent,
		FacilityInfoFormComponent,
		ContactInfoFormComponent,
		CurrencyAmountComponent,
		FacilitiesAppFormComponent,
		AddFacilityComponent,
		InternalProductsRequiringApprovalComponent,
		ContactsAppFormComponent,
		ContractFormComponent,
		InvoiceSettingsFormComponent,
		CompanyContractAppFormComponent,
		PrivateLabelerContractAppFormComponent,
		PrivateLabelerContractComponent,
		InvoicingGroupsAppFormComponent,
		BusinessEntitySearchComponent,
		BusinessEntityCompareInfoComponent,
		CompanyAppFormComponent,
		AddLinkOptionComponent,
		VisitsListComponent,
		AddContactComponent,
		InitialVisitAppFormComponent,
		ViewScheduleComponent,
		TripListComponent,
		SelectTypeAddVisitComponent,
		TripInfoFormComponent,
		VisitInfoViewComponent,
		CommunicationChatComponent,
		PrivateLabelerAppFormComponent,
		TransactionsListComponent,
		ApplicationPageHeaderComponent,
		AccordionGroupComponent,
		AddFacilityInfoComponent,
		AddPrivateLabelerInfoComponent,
		TaskInfoFormComponent,
		TaskControlsFormComponent,
		TaskEntitySearchComponent,
		ExpensesListComponent,
		AddExpenseComponent,
		AddMemberComponent,
		AddUserComponent,
		MasterIngredientsListComponent,
		SearchInputComponent,
		IngredientCertificateAttributeComponent,
		MasterIngredientGeneralInfoComponent,
		MasterIngredientKosherInfoComponent,
		MasterIngredientKosherEvaluationComponent,
		PackagingComponent,
		MasterIngredientCurrentCertificateComponent,
		ViewCellRendererComponent,
		DatePickerInputComponent,
		CustomerIngredientSideinfoComponent,
		GenericGridSearchModalComponent,
		GridWrapperComponent,
		SelectMultipleInputComponent,
		CustomerIngredientGeneralInfoComponent,
		CustomerIngredientsMasterIngredientComponent,
		AuditPipe,
		CamelcaseDisplayPipe,
		OptionPipe,
		PurifyPipe,
		ConditionalValuePipe,
		RenderValueFromListPipe,
		HistoryDisplayItemComponent,
		InvoicesTabComponent,
		DynamicMenuComponent,
		QcChecklistComponent,
		ExpensesTableComponent,
		PreviewInvoiceComponent,
		PreviewInvoiceApTableComponent,
		PreviewInvoiceApTableVisitsComponent,
		AddMasterIngredientComponent,
		EntityFacilitiesListComponent,
		CompareListComponent,
		UndoCellRendererComponent,
		GenericViewChangesModalComponent,
		AccordionComponent,
		GenericValidationModalComponent,
		SummaryFieldListComponent,
		DurationPickerComponent,
		CertificatePreviewModalComponent,
		FeesAndExpensesInputFormComponent,
		VisitFeeCellFooterComponent,
		ViewTermAuditComponent,
		PaymentMethodInputComponent,
		GenericSearchGridComponent,
		ViewPdfComponent,
		NoteListComponent,
		EntityProfileAsweredQuestionsListComponent,
		IngredientFacilitiesComponent,
		OrderByPipe,
		ProductCategoriesListComponent,
		LinkCategoryModalComponent,
		CompanyProductsListComponent,
		ProcessBatchIngredientErrorsModalComponent,
		ViewOutstandingInvoicesComponent,
		KosherCertificatesFormComponent,
		KcGroupComponent,
		CreateKcgroupComponent,
		GenericGridComponent,
		UploadFormulaItemsComponent,
		CustomerIngredientsListComponent,
		VendorsListComponent,
		AdditionalFeesInvoicesComponent,
		DataLossModalComponent,
		LookUpInputComponent,
		IncompleteApplicationsListComponent,
		ButtonCellRendererComponent,
		PermissionsListComponent,
		StaticBarComponent,
		IngredientBatchListComponent,
		ProductFormulaReportComponent,
		ScriptsListComponent,
		EntityGuidelinesComponent,
		AdvancedPermissionsListComponent,
		DiscrepanciesComponent,
		RightClickMenuComponent,
		FieldRepExpensesComponent,
		MergeBusinessEntityGridHeaderComponent,
		EntityFormComponent,
	],
	entryComponents: [
		SelectMultipleInputComponent,
		NoRowsComponent,
		ConfirmComponent,
		AddRoleComponent,
		PermissionsHeaderComponent,
		AddBrandComponent,
		AddNoteComponent,
		CurrencyAmountComponent,
		AddFacilityComponent,
		BusinessEntityCompareInfoComponent,
		LinkEntityComponent,
		AddLinkOptionComponent,
		VisitsListComponent,
		AddVisitsComponent,
		AddContactComponent,
		ViewScheduleComponent,
		TripListComponent,
		SelectTypeAddVisitComponent,
		AddTripComponent,
		AddTaskComponent,
		AddFacilityInfoComponent,
		AddPrivateLabelerInfoComponent,
		SearchEntityComponent,
		ExpensesListComponent,
		AddExpenseComponent,
		AddGroupComponent,
		AddMemberComponent,
		AddUserComponent,
		MasterIngredientsListComponent,
		UploadAmexExpensesComponent,
		UploadAmexExpensesTripComponent,
		UploadAmexExpensesGridComponent,
		CustomerIngredientSideinfoComponent,
		GenericGridSearchModalComponent,
		AddVendorComponent,
		AddAgencyComponent,
		CompareListComponent,
		EntityFacilitiesListComponent,
		AddProfileQuestionComponent,
		FormAnswerTypeRendererComponent,
		DurationPickerComponent,
		InvoicesListComponent,
		ViewTermAuditComponent,
		MergeBusinessEntityGridHeaderComponent,
		AddContactToNotificationWhitelistComponent,
	],
	providers: [
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		FilterPipe,
		NoSanitizePipe,
		DatePipe,
		AuditPipe,
		CamelcaseDisplayPipe,
		OptionPipe,
		DateTimePipe,
		ArrayLenghtIsPipe,
		DataLossPreventionGuard,
		UserPermissionGuard,
		PurifyPipe,
		ConditionalValuePipe,
		RenderValueFromListPipe,
		CompanyDefaultInvoiceGroupGuard,
	],
})
export class SharedModule {}
