import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NotifierService } from 'angular-notifier';
import { AccountingService } from 'src/app/core/services/accounting.service';
import { ByteArrayHelperService } from 'src/app/core/services/helpers/byte-array-helper.service';

@Component({
	selector: 'app-download-invoice-renderer',
	templateUrl: './download-invoice-renderer.component.html',
	styleUrls: ['./download-invoice-renderer.component.scss'],
})
export class DownloadInvoiceRendererComponent implements ICellRendererAngularComp {
	invoiceHeaderId: number;
	loading: boolean;

	constructor(
		private accountingSvc: AccountingService,
		private notifierService: NotifierService,
		private byteArrayHelperService: ByteArrayHelperService
	) {}

	agInit(params: any): void {
		this.invoiceHeaderId = params.data.id;
	}

	refresh(): boolean {
		return false;
	}

	downloadInvoice(): void {
		if (this.loading) {
			return;
		}

		this.loading = true;
		this.accountingSvc.export(this.invoiceHeaderId).subscribe(response => {
			if (response && response.length > 0) {
				response.forEach(element => {
					const sampleArr = this.byteArrayHelperService.base64ToArrayBuffer(element.content);
					this.byteArrayHelperService.saveByteArray(element.name, sampleArr, 'application/pdf');
				});
			} else {
				this.notifierService.notify('error', 'Invoice print is not available.');
			}

			this.loading = false;
		});
	}
}
