import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridType } from 'src/app/core/enums/grid-type.enum';
import { IDataService } from 'src/app/core/interfaces/i-data-service.interface';
import {
	Contact,
	ContactFlat,
	ContactInfo,
	ContactPerson,
	ContactSummary,
	ContactSummaryDto,
} from 'src/app/core/models/contact.model';
import { SearchPaging } from 'src/app/core/models/general/search-paging.model';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { ActiveType } from '../enums/active-type.enum';
import { Address } from '../models/address.model';
import { BaseModel } from '../models/base.model';
import { ContactAction } from '../enums/contat-actions.enum';

@Injectable({
	providedIn: 'root',
})
export class ContactService implements IDataService {
	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	search(request: SearchPaging, filter: any, gridType: GridType) {
		const requestModel = {
			model: request,
			criteria: {
				keyword: filter.keyword,
				filterDropdown: filter.filterDropdown,
				contacts: filter.contacts,
				filterType: filter.filterType,
			},
			template: gridType,
		};

		return this.http.post(`${this.appConfigurationService.api.contact}/contact/search`, requestModel);
	}

	get(id): Observable<Contact> {
		return this.http.get<Contact>(`${this.appConfigurationService.api.service}/contact/load`, { params: { id } });
	}

	getFlat(id, businessEntityId = null): Observable<ContactFlat> {
		if (businessEntityId) {
			return this.http.get<ContactFlat>(`${this.appConfigurationService.api.contact}/contact/load/flat`, {
				params: { id, businessEntityId },
			});
		} else {
			return this.http.get<ContactFlat>(`${this.appConfigurationService.api.contact}/contact/load/flat`, {
				params: { id },
			});
		}
	}

	summary(id: number, activeType: ActiveType): Observable<ContactSummary> {
		return this.http.get<ContactSummary>(
			`${this.appConfigurationService.api.contact}/Contact/${id}/Summary?activeType=${activeType}`
		);
	}

	contactInfoSummary(id) {
		return this.http.get(`${this.appConfigurationService.api.service}/summary/contact?id=${id}`);
	}

	contactsListInfoSummary(contactIds): Observable<ContactSummaryDto[]> {
		return this.http.post<ContactSummaryDto[]>(
			`${this.appConfigurationService.api.service}/summary/contacts`,
			contactIds
		);
	}

	save(contact: Contact, validateAddress = false): Observable<object> {
		return this.http.post(
			`${this.appConfigurationService.api.service}/contact/save?validateAddress=${validateAddress}`,
			contact
		);
	}

	activateInactivateContact(contactId: number, contactAction: ContactAction): Observable<ContactInfo> {
		return this.http.post<ContactInfo>(
			`${this.appConfigurationService.api.contact}/contact/activating_inactivating?contactId=${contactId}&contactAction=${contactAction}`,
			{}
		);
	}

	infoSummary(id): Observable<any> {
		return this.http.get<any>(
			`${this.appConfigurationService.api.businessEntity}/BussinessEntity/${id}/ContactSummary`
		);
	}

	exportSearch(request: SearchPaging, filters: any, gridType: GridType) {
		const requestModel = {
			model: request,
			criteria: {
				keyword: filters.keyword,
				filterDropdown: filters.filterDropdown,
			},
			template: gridType,
		};

		return this.http.post(`${this.appConfigurationService.api.contact}/contact/export`, requestModel);
	}

	getContactName(id): Observable<ContactFlat> {
		return this.http.get<ContactFlat>(`${this.appConfigurationService.api.contact}/contact/load/flat`, {
			params: { id },
		});
	}

	getContactsByEmailAddress(email: string): Observable<ContactFlat[]> {
		return this.http.get<ContactFlat[]>(
			`${this.appConfigurationService.api.contact}/Contact/Load/FlatByEmailAddress?emailAddress=${email}`
		);
	}

	getInternalContacts(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.service}/contact/loadinternal?useDefaultValue=${useDefaultValue}`
		);
	}

	getAddresses(ids: number[]) {
		return this.http.post<Address[]>(`${this.appConfigurationService.api.service}/contact/Address/Load`, ids);
	}

	loadUserContacts(userId: number) {
		return this.http.post<ContactPerson[]>(
			`${this.appConfigurationService.api.service}/contact/loadUsersContacts`,
			[userId]
		);
	}

	hasRabbinicalAssignments(contactId): Observable<Contact> {
		return this.http.get<Contact>(
			`${this.appConfigurationService.api.contact}/contact/has-rabbinical-assignments`,
			{ params: { contactId } }
		);
	}

	hasAccountRepAssignments(contactId): Observable<Contact> {
		return this.http.get<Contact>(
			`${this.appConfigurationService.api.contact}/contact/has-account-rep-assignments`,
			{ params: { contactId } }
		);
	}
}
