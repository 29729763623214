export enum GridType {
	COMPANIES = 'Companies',
	FACILITIES = 'Facilities',
	ADD_NEW_PRODUCT_FACILITIES = 'AddNewProductFacilities',
	PRIVATE_LABELERS = 'PrivateLabelers',
	AGENCIES = 'Agency',
	VENDORS = 'Vendor',
	VENDOR_AGENCIES = 'VendorAgencies',
	VENDOR_CHILDREN = 'VendorChildren',
	CONTACTS = 'Contact',
	BUSINESS_ENTITY_CONTACTS = 'BusinessEntityContacts',
	CONTACT_LINKS = 'ContactLinks',
	CONTACT_RABBINICAL_ASSIGNMENTS = 'ContactRabbinicalAssignments',
	CONTACT_ACCOUNT_REP_ASSIGNMENTS = 'ContactAccountRepAssignments',
	COMPANY_PRIVATE_LABELERS = 'CompanyPrivateLabelers',
	FACILITY_COMPANIES = 'FacilityCompanies',
	COMPANY_FACILITIES = 'CompanyFacilities',
	PRIVATE_LABELER_COMPANIES = 'PrivateLabelerCompanies',
	ENTITY_BRANDS = 'Brands',
	BRANDS_LOOKUP = 'LookUpBrands',
	ENTITY_ALIAS = 'Alias',
	PERMISSIONS = 'Role',
	ROLES = 'Role',
	ROLE_USERS = 'RoleUsers',
	ENTITY_NOTES = 'BusinessEntityNotes',
	APPLICATIONS = 'Application',
	VISITS = 'Visits',
	ADD_VISIT_TO_TRIP = 'VisitsToTrip',
	TRIPS = 'Trips',
	FACILITY_VISITS = 'FacilityVisits',
	FACILITY_TRIPS = 'FacilityTrips',
	COMPANY_FACILITIES_INACTIVATE = 'CompanyFacilitiesInactivate',
	COMPANY_PRIVATE_LABELERS_INACTIVATE = 'CompanyPrivateLabelersInactivate',
	COMPANY_FACILITIES_ACTIVATE = 'CompanyFacilitiesActivate',
	COMPANY_PRIVATE_LABELERS_ACTIVATE = 'CompanyPrivateLabelersActivate',
	FACILITY_COMPANIES_INACTIVATE = 'FacilityCompaniesInactivate',
	FACILITY_COMPANIES_ACTIVATE = 'FacilityCompaniesActivate',
	TASK = 'Task',
	COMPANY_PRIVATE_LABELER_AGREEMENT = 'CompanyPrivateLabelerAgreement',
	GROUP = 'Group',
	USER_GROUP = 'UserGroup',
	USER = 'User',
	MASTER_INGREDIENTS = 'MasterIngredients',
	FILE_PAYMENT = 'FilePayment',
	FILE_PAYMENT_ITEM = 'FilePaymentItem',
	FILE_PAYMENT_TRIP_ITEM = 'FilePaymentTripItem',
	CUSTOMER_INGREDIENT = 'CustomerIngredient',
	LOOK_UP_AGENCY = 'LookUpAgency',
	LOOK_UP_VENDOR = 'LookUpVendor',
	INGREDIENT_BATCHES_HEADERS = 'IngredientBatchesHeaders',
	MASTER_INGREDIENT_FACILITIES = 'MasterIngredientFacilities',
	MASTER_INGREDIENT_COMPANIES = 'MasterIngredientCompanies',
	LOOK_UP_UKD = 'LookUpUKD',
	CLEANUP_CONTRACT_COMPANY_FACILITY = 'CleanupContractCompanyFacility',
	CLEANUP_CONTRACT_FIELD_REP = 'CleanupContractFieldRep',
	PURCHASE_HISTORY = 'PurchaseHistory',
	CUSTOMER_INGREDIENT_FACILITY = 'CustomerIngredientFacility',
	PRODUCT = 'Product',
	FACILITY_INSTRUCTIONS = 'FacilityInstructions',
	FACILITY_PRODUCT = 'FacilityProduct',
	PRODUCT_FACILITY = 'ProductFacility',
	SELECTABLE_PRODUCT_FACILITY = 'SelectableProductFacility',
	PRODUCT_PROCESSES = 'ProductProcesses',
	CUSTOMER_INGREDIENT_SIMILAR_INGREDIENT = 'CustomerIngredientSimilarIngredients',
	COMPANY_FACILITY_AGREEMENT = 'CompanyFacilityAgreement',
	PRODUCT_BATCHES_HEADERS = 'ProductBatchesHeaders',
	PRODUCTS_SAME_AS_BASE_PRODUCT = 'ProductsSameAsBaseProduct',
	ENTITY_FACILITIES = 'EntityFacilities',
	DEPENDENT_PRODUCTS = 'DependentProducts',
	CUSTOMER_INGREDIENT_LOOKUP = 'CustomerIngredientLookup ',
	BATCH_INGREDIENT_ITEMS = 'BatchIngredientItems',
	INGREDIENT_CATEGORY = 'IngredientCategory',
	VISIT_PRODUCTS = 'VisitProducts',
	CUSTOMER_INGREDIENT_RELATED_BATCH_INGREDIENTS = 'CustomerIngredientRelatedBatchIngredients',
	PRODUCT_CERTIFICATES = 'ProductCertificates',
	PRODUCT_PRODUCTION_HISTORY = 'ProductProductionHistory',
	CUSTOMER_INGREDIENT_SUBSET = 'CustomerIngredientSubset',
	PRODUCT_EVALUATION_IDENTITY_CODES = 'ProductEvaluationIdentityCodes',
	PRODUCT_FORMULAS = 'ProductFormulas',
	CERTIFICATES = 'Certificates',
	PRODUCT_LABELS = 'ProductLabel',
	PRODUCT_CERTIFICATE_GROUP = 'ProductCertificateGroup',
	PRODUCT_BATCH_CERTIFICATE_GROUP = 'ProductBatchesCertificateGroups',
	COMPANIES_RENEWAL = 'CompaniesRenewal',
	MASTER_INGREDIENT_HISTORY = 'MasterIngredientHistory',
	DOCUMENT_TEMPLATES = 'DocumentTemplates',
	MASTER_INGREDIENT_DEPENDENT_PRODUCTS = 'MasterIngredientDependentProducts',
	CUSTOMER_INGREDIENT_DEPENDENT_PRODUCTS = 'CustomerIngredientDependentProducts',
	RELATED_BATCH_INGREDIENT_ITEMS = 'RelatedBatchIngredientItems',
	CERTIFICATE_GROUP = 'CertificateGroup',
	INVOICES = 'Invoices',
	PRODUCER = 'Producer',
	LOOK_UP_CONTACT = 'LookUpContact',
	REVIEW_BATCH_INGREDIENT_ITEMS = 'ReviewBatchIngredientItems',
	COMPANIES_UNDERGOING_RENEWAL = 'CompaniesUndergoingRenewal',
	RENEWAL_INVOICES = 'RenewalInvoices',
	BUSINESS_ENTITIES_OWNERS = 'BusinessEntityOwners',
	CUSTOMER_INGREDIENT_RELATED_PRODUCTS = 'ProductToCustomerIngredient',
	MASTER_INGREDIENTS_UNIVERSAL_INGREDIENT = 'UniversalIngredients',
	MASTER_INGREDIENT_CHANGES = 'MasterIngredientChanges',
	COMPANY_RENEWAL_HISTORY = 'CompanyRenewalHistory',
	COMPANY_SECONDARY_ROUTING_INFO = 'CompanyKosherCertInfoSecondaryRoutingInfo',
	MASTER_INGREDIENT_SAME_AS_BASE_INGREDIENT = 'MasterIngredientSameAsBaseIngredient',
	LOOKUP_KID = 'IdentityCodeProduct',
	CERTIFICATE_IDENTITY = 'CertificateIdentity',
	CERTIFICATE_ITEM = 'CertificateItem',
	PRODUCT_BATCHES_FORMULA_ITEM_RELATED_ITEM = 'ProductBatchesFormulaItemRelatedItem',
	PRODUCT_BATCHES_FORMULA_ITEM = 'ProductBatchesFormulaItem',
	PRODUCT_BATCHES_FORMULA_EVALUATIONS = 'ProductBatchesFormulaEvaluations',
	PRODUCT_BATCHES_EVALUATION_FORMULAS = 'ProductBatchesEvaluationFormulas',
	PRODUCT_FORMULA_ITEM = 'FormulaItem',
	PRODUCT_EVALUATIONS = 'ProductEvaluation',
	PRODUCT_FORMULA_RELATED_ITEM = 'FormulaItemRelatedItem',
	PRODUCT_FORMULA_FACILITIES = 'ProductFormulasFacilities',
	LOOKUP_FORMULA_ITEM = 'LookUpFormulaItem',
	LOOKUP_FORMULA_ITEM_INGREDIENT = 'LookUpFormulaItemIngredients',
	PRODUCT_LOOKUP = 'LookUpProduct',
	COMPANY_CERTIFICATE = 'CompanyCertificate',
	IDENTITY_CODE_PRODUCT_MULTIPLE = 'IdentityCodeProductMultiple',
	FACILITY_CUSTOMER_INGREDIENTS = 'FacilityCustomerIngredients',
	AGENCY_VENDOR = 'AgencyVendor',
	AGENCY_FACILITIES = 'AgencyFacility',
	INCOMPLETE_APPLICATION = 'IncompleteApplication',
	UKD = 'Ukd',
	UKD_HISTORY = 'UkdHistory',
	DASHBOARD_TASKS = 'DashboardTasks',
	DASHBOARD_LATEST_COMMUNICATIONS_TASKS = 'DashboardLatestCommunicationsTasks',
	ENTITY_TASKS = 'EntityTasks',
	COMPANY_PROCESSES = 'CompanyProcesses',
	FACILITY_PROCESSES = 'FacilityProcesses',
	ENTITY_AUDIT = 'EntityAudit',
	PRODUCT_BATCHES_FORMULA_FACILITIES = 'ProductBatchesFormulaFacilities',
	REVIEW_PRODUCTS = 'ReviewProducts',
	BATCH_PRODUCT_ITEMS = 'BatchProductItems',
	UNMATCHED_FORMULA_ITEMS = 'UnmatchedFormulaItems',
	UNPROCESSED_DEPENDENT_BATCHES_ITEMS = 'UnprocessedDependentBatchItems',
	CATEGORY = 'Category',
	CATEGORY_LINKED_ENTITIES = 'CategoryLinkedEntities',
	FACILITY_FORMULA_INGREDIENTS_UTILITY = 'FacilityFormulaIngredientsUtility',
	DASHBOARD_INGREDIENT_BATCHES_HEADERS = 'DashboardIngredientBatchesHeaders',
	PRODUCTS_ONLY = 'ProductsOnly',
	FACILITY_CONTRACT_FIELD_REP_TERMS = 'FacilityContractFieldRepTerms',
	CODE_CHANGE_UTILITY = 'CodeChangeUtility',
	CUSTOMER_INGREDIENT_MERGE_BATCH_INGREDIENT = 'CustomerIngredientMergeBatchIngredient',
	REGION_FEE_ATTRIBUTES = 'RegionFeeAttributes',
	COMPANY_FACILITY_VISIT_INFO = 'CompanyFacilityVisitInfo',
	CERTIFICATE_SUB_GROUP = 'CertificateSubGroup',
	NOTIFICATION_WHITELIST = 'NotificationWhiteList',
	PRODUCTS_ONLY_FACILITY = 'ProductsOnlyFacility',
	CONTACTS_FOR_NOTIFICATION_WHITELIST = 'ContactsForNotificationWhiteList',
	PRIVATE_LABELER_COMPANIES_INACTIVATE = 'PrivateLabelerCompaniesInactivate',
	PRIVATE_LABELER_COMPANIES_ACTIVATE = 'PrivateLabelerCompaniesActivate',
	COMPANY_RENEWAL_FACILITIES = 'CompanyRenewalFacilities',
	COMPANY_RENEWAL_FACILITIES_HISTORY = 'CompanyRenewalFacilitiesHistory',
	COMPANY_RENEWAL_PRIVATE_LABELERS = 'CompanyRenewalPrivateLabelers',
	FACILITY_CONTRACT_NOTES = 'FacilityContractNotes',
	VISITS_GENERATE_INVOICES = 'VisitsGenerateInvoices',
	TRIPS_GENERATE_INVOICES = 'TripsGenerateInvoices',
	ACUMATICA_LOGS = 'AcumaticaLogs',
	TWO_WAY_SYNC_LOGS = 'TwoWaySyncLogs',
}
